import styled from "@emotion/styled";
import {
  BgHolesContainer,
  BgHole,
  BetPane,
  OneStrike,
  TwoStrike,
  NoStrike,
  Border,
  RollAgainDialog,
  RollAgainFemale,
  SideBetAccept,
  SideBetDecline,
  CricketPaneContainer,
  OutBar,
  CricketOutPerson,
  MetalBeamBackground,
  ParticleTileClick,
} from "assets/images";
import {
  NewDice1,
  NewDice2,
  NewDice3,
  NewDice4,
  NewDice5,
  NewDice6,
} from "assets/images/dices";
import { sizes } from "service";
import { mixins } from "theme";
import { colors } from "theme";

export const RollingContainer = styled.div`
  position: relative;
  width: 100%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .out-container {
    background-image: url(${OutBar});
    background-size: 100% 100%;
    height: 235px;
    width: 857px;
    position: absolute;
    z-index: 2;
    top: 31%;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.1s cubic-bezier(.71,0,.33,1.56) 0.1ms;
    animation: bgAppear 800ms;
    span {
      position: relative;
      font-weight: bold;
      color: #fff;
      font-family: "Varsity Team Bold" !important;
      font-size: 134px;
      top: 6%;
      animation: fromLeftOut 800ms;
      opacity: 0;
    }
  }

  .out-person {
    pointer-events: none;
    background-image: url(${CricketOutPerson});
    background-size: 100% 100%;
    height: 390px;
    width: 300px;
    position: absolute;
    z-index: 3;
    top: 79%;
    left: 62%;
    opacity: 0;
    animation: fromRightToLeftOutPerson 1.5s;

    @media (max-height: 1368px) {
      top: 86%;
    }
    @media (max-height: 1277px) {
      top: 78%;
    }
    @media (max-height: 1180px) {
      top: 69%;
    }
    @media (max-height: 1137px) {
      top: 63%;
    }
    @media (max-height: 914px) {
      top: 64%;
    }
    @media (max-height: 653px) {
      top: 66%;
    }
  }

  @keyframes fromRightToLeftOutPerson {
    0% {
      right: -40%;
      opacity: 0;
      z-index: 3;
    }
    20% {
          left: 57%;
      opacity: 1;
      z-index: 3;
    }
    30% {
          left: 57%;
      opacity: 1;
      z-index: 3;
    }
    40% {
          left: 57%;
      opacity: 1;
      z-index: 3;
    }
    50% {
          left: 57%;
      opacity: 1;
      z-index: 3;
    }
    60% {
          left: 57%;
      opacity: 1;
      z-index: 3;
    }
    70% {
          left: 57%;
      opacity: 1;
      z-index: 3;
    }
    80% {
          left: 57%;
      opacity: 1;
      z-index: 3;
    }
    100% {
      right: -40%;
      opacity: 0;
      z-index: 1;
    }
  }

  @keyframes fromLeftOut {
    0% {
      left: -30%;
      opacity: 0;
      z-index: 9;
    }
    20% {
      /* left: 35%; */
      opacity: 1;
      z-index: 9;
    }
    30% {
      /* left: 35%; */
      opacity: 1;
      z-index: 9;
    }
    40% {
      /* left: 35%; */
      opacity: 1;
      z-index: 9;
    }
    50% {
      /* left: 35%; */
      opacity: 1;
      z-index: 9;
    }
    60% {
      /* left: 35%; */
      opacity: 1;
      z-index: 9;
    }
    70% {
      /* left: 35%; */
      opacity: 1;
      z-index: 9;
    }
    80% {
      /* left: 35%; */
      opacity: 1;
      z-index: 9;
    }
    100% {
      left: 0;
      opacity: 0;
      z-index: 1;
    }
  }

  @keyframes bgAppear {
    0% {
     opacity: 0;
     visibility: initial;
    }
    100% {
      opacity: 1;
      visibility: initial;
    }
  }

  .noball-container {
    position: absolute !important;
    z-index: 4 !important;
    width: 1000px;
    height: 500px;
    position: relative;
    margin: 100px auto;
    text-align: center;
    font-family: sans-serif;
    font-size: 89px;
    font-weight: bold;
    color: #fff;
    font-family: "Varsity Team Bold" !important;
    width: 100%;
    height: 485px;
    overflow: hidden;
    border-radius: 15px;
    background-color: rgba(0,0,0,0.6);
  }

  .from-right {
    opacity: 0;
    position: absolute;
    top: 42%;
    transition: all 0.1s cubic-bezier(.71,0,.33,1.56) 0.1ms;
    animation: fromRight 800ms;
  }

  @keyframes fromRight {
    0% {
      right: -200px;
    }
    30%{
      opacity: 1;
      right: 28%;
    }
    40%{
      opacity: 1;
      right: 28%;
    }
    50%{
      opacity: 1;
      right: 28%;
    }
    60%{
      opacity: 1;
      right: 28%;
    }
    70%{
      opacity: 1;
      right: 28%;
    }
    80%{
      opacity: 1;
      right: 28%;
    }
    100% {
      right: -200px;
    }
  }

  .from-left {
    opacity: 0;
    position: absolute;
    top: 24%;
    transition: all 0.1s cubic-bezier(.71,0,.33,1.56) 0.1ms;
    animation: fromLeft 800ms;
  }

  @keyframes fromLeft {
    0% {
      left: -117px;
    }
    30%{
      opacity: 1;
      left: 38%;
    }
    40%{
      opacity: 1;
      left: 38%;
    }
    50%{
      opacity: 1;
      left: 38%;
    }
    60%{
      opacity: 1;
      left: 38%;
    }
    70%{
      opacity: 1;
      left: 38%;
    }
    80%{
      opacity: 1;
      left: 38%;
    }
    100% {
      left: -117px;
    }
  }

  .auto-pick-toggle {
    margin-top: auto;
    cursor: pointer;
  }
  #animation_container {
    position: absolute;
    z-index: 5;
    width: 100% !important;
    height: auto !important;
    top: 340px;
    #canvas {
      background-color: transparent;
      position: absolute;
      display: block;
      width: 500px !important;
      height: 270px !important;
    }
    #dom_overlay_container {
      pointer-events: none;
      overflow: hidden;
      width: 640px;
      height: 480px;
      position: absolute;
      left: 0;
      top: 0;
      display: block;
    }
  }
  .saving-loader {
    position: absolute;
    z-index: 1;
    color: white;
    .ant-spin-dot {
      .ant-spin-dot-item {
        background-color: white;
      }
    }
  }
  @media screen and (max-height: 1100px) {
    #animation_container {
      top: 335px;
      #canvas {
        height: 300px !important;
      }
    }
  }
  .rolling-wrapper {
    width: ${sizes.mainWidth};
    min-height: 1040px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .jackpot-wrapper {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .bars-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .streak-pay-container {
        position: relative;
        width: calc(90% - 10px);
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: url(${Border});
        background-size: 100% 100%;
        .streak-star-img {
          position: absolute;
          right: -16px;
        }
        .streak-bonus-overlay {
          transition: width 0.5s ease;
          width: calc(${(props) => props.rollCnt > 15 ? 100 : (props.rollCnt / 15) * 100}% - ${props => props.rollCnt > 1 ? '25px' : '0px'});
          height: calc(100% - 4px);
          background-image: linear-gradient(
            0deg,
            #81f305 0%,
            #009900 50%,
            #009121 75%,
            #81f305 100%
          );
          border-radius: 50px;
          margin: 0 2px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .streak-bonus-opacity {
          position: absolute;
          transition: width 0.5s ease;
          width: calc(${(props) => props.rollCnt > 15 ? 100 : (props.rollCnt / 15) * 100}% - ${props => props.rollCnt > 1 ? '25px' : '0px'});
          height: calc(100% - 4px);
          background: linear-gradient(
            90deg,
            rgba(204, 255, 0, 0) 0%,
            rgba(204, 255, 0, 0) 50%,
            rgba(204, 255, 0, 0.5) 75%,
            rgba(204, 255, 0, 1) 100%
          );
          border-radius: 50px;
          margin: 0 2px;
        }
        .streak-pay--leftside {
          position: relative;
          flex: 1;
          display: flex;
          align-items: center;
          .key-container {
            position: absolute;
            right: -52px;
            z-index: 3;
          }
        }
        .streak-pay--rightside {
          width: ${sizes.betWidth}px;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          padding-left: 60px;
        }
      }
    }
    .bet-pane-container {
      position: relative;
      width: ${sizes.mainWidth}px;
      height: ${sizes.mainWidth}px;
      background: url(${BetPane});
      background-size: 100% 100%;
      margin: 20px 0;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: center;
      .bet-ins-mask {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
        position: absolute;
        margin: auto;
        width: 100%;
        min-height: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: 2;
        padding: 20px 40px;
        .bet-ins-mask-content {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          .fake-dice {
            position: absolute;
            left: 0;
            top: 77px;
          }
          .handler-bet {
            position: absolute;
            bottom: -41px;
          }
        }
      }
      .bet-pane-title {
        font-size: 22px;
        color: #ffff45;
        background: linear-gradient(
          360deg,
          #fbe741 25%,
          #dba323 53%,
          #fbe741 72%
        );
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        font-weight: 900;
        font-family: "Roboto";
        text-align: center;
        filter: drop-shadow(0px 10px 10px rgba(0, 71, 147, 0.5));
        text-transform: uppercase;
      }
      .bet-pane-description {
        color: white;
        font-size: 18px;
        font-family: "Roboto";
        text-align: center;
      }
      span {
        font-family: "Roboto";
        font-weight: 700;
        color: white;
        margin-bottom: 9px;
      }
      .one-two-strikes {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
        margin-bottom: 20px;
        .bet-pane-card-title {
          font-size: 16px;
          color: #ffff45;
          background: linear-gradient(
            360deg,
            #fbe741 25%,
            #dba323 53%,
            #fbe741 72%
          );
          -webkit-text-fill-color: transparent;
          background-clip: text;
          -webkit-background-clip: text;
          font-weight: 900;
          font-family: "Roboto";
          text-align: center;
          filter: drop-shadow(0px 10px 10px rgba(0, 71, 147, 0.5));
          text-transform: uppercase;
          z-index: 1001;
        }
        .one-strike-wrapper {
          flex: 1;
          .one-strike {
            height: 60px;
            background: url(${OneStrike});
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-size: 20px;
          }
        }
        .two-strike-wrapper {
          flex: 1;
          .two-strike {
            height: 60px;
            background: url(${TwoStrike});
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-size: 20px;
          }
        }
      }
      .no-strikes {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-bottom: 55px;
        span {
          font-size: 30px;
        }
        .no-strike-bg {
          width: 80%;
          height: 80px;
          background: url(${NoStrike});
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
        }
      }
    }
    .pane-container {
      position: relative;
      width: ${sizes.mainWidth}px;
      height: ${sizes.mainWidth}px;
      background: url(${CricketPaneContainer});
      background-size: 100% 100%;
      // margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      .particle-hit-anim {
        position: absolute;
        background-image: url(${ParticleTileClick});
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 150px;
        width: 150px;
        z-index: 99999;
        left: -39px;
        top: -46px;
        opacity: 0;
        pointer-events: none;
      }
      .free-pane {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;
        background-color: rgba(0, 0, 0, 0.6);
      }
      .stop-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin: auto;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: 1;
      }

      .covered-mask {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin: auto;
        width: 100%;
        height: 100%;
        background: transparent;
        z-index: 4;
        color: white;
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 1.4px;

        &.bonus {
          background: rgba(0, 0, 0, 0.6);
        }
      }
      .ins-mask {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: absolute;
        margin: auto;
        width: 100%;
        min-height: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: 2;
        padding: 40px;
        .ins-mask-header {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
          .icon-bone {
            position: absolute;
            left: 0px;
          }
          .ins-mask-title {
            @media (max-width: ${mixins.mobileWidth}px) {
              font-size: 22px !important;
            }
          }
        }
        .btn-gotit {
          margin-top: 15px;
          cursor: pointer;
        }
      }
      .skipping-stars {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin: auto;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      .pane {
        padding: 15px;
        .ant-row {
          position: relative;
          z-index: 2;
          .ant-col {
            &.status-3 {
              z-index: 1 !important;
            }
          }
        }
      }
      .pane-mask {
        position: absolute;
        bottom: -20px;
        background: rgba(0, 0, 0, 0.9);
        border-radius: 10px;
        border: solid dimgray 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 44px;
      }
      div.game-over {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba(0,0,0, 0.6);

        .game-over-center {
          position: relative;

          img {
            width: 283px;
            height: 180px;
          }

          .title {
            position: absolute;
            top: 0;
            text-align: center;
            width: 100%;
            font-weight: bold;
            color: #ffffff;
            font-size: 22px;
            margin-top: 35px;
            user-select: none;
          }

          .message {
            position: absolute;
            width: 100%;
            bottom: 38px;
            font-size: 34px;
            font-weight: bold;
            color: ${colors.yellow};
            text-align: center;
            user-select: none;
          }
          .message-es {
            line-height: 1;
            top: 86px;
            font-size: 29px;
          }
          .message-pt {
            line-height: 1;
            top: 81px;
          }
        }
      }
    }
    .dice {
      display: flex;
      justify-content: center;
      .die-container {
        .die {
          transition: transform 0.9s ease;
          .face {
            box-shadow: inset -0.35rem 0.35rem 0.75rem rgba(0, 0, 0, 0.3),
              inset 0.5rem -0.25rem 0.5rem rgba(0, 0, 0, 0.15);
            border-radius: 13px;
            border: solid 1px white;
          }
        }
      }
    }
    .btn-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      min-height: 105px;
      .guide-img,
      .paytable-img {
        min-width: 50px;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }
    }
    .instruction-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 10px;
      border-radius: 10px;
      .toggle-wrapper-md {
        display: flex;
        flex-direction: column;
        align-items: center;
        h5 {
          color: white;
          font-size: 14px;
        }
      }
      .toggle-wrapper-sm {
        display: none;
      }
      .icon-music {
        font-size: 34px;
        color: white;
        cursor: pointer;
      }
      @media (max-height: 870px) {
      }
      @media (max-height: 820px) {
      }
    }
  }
  .first-covered-popup {
    position: absolute;
    background-image: url(${RollAgainDialog});
    background-size: 100% 100%;
    width: 224px;
    height: 128px;
    top: 30px;
    left: 20px;
  }
  .first-covered-popup-character {
    position: absolute;
    background-image: url(${RollAgainFemale});
    background-size: 100% 100%;
    width: 140px;
    height: 150px;
    top: 20px;
    right: 5px;
  }

  @media (max-width: ${mixins.mobileWidth}px),
    (max-height: ${mixins.ipadHeight}px) {
    margin-top: 0px;
    .rolling-wrapper {
      .bars-container {
        .roll-bonus {
          font-size: 15px !important;
        }
        .streak-pay-container {
          margin-bottom: 0px;
          .streak-pay--leftside {
            .key-container {
              right: -37px;
              .ant-image {
                width: 43px !important;
              }
            }
          }
          .streak-pay--rightside {
            width: 90px;
            padding-left: 40px;
          }
          .streak-bonus-overlay {
            transition: width 0.5s ease;
            width: calc(${(props) => props.rollCnt > 15 ? 100 : (props.rollCnt / 15) * 100}% - ${props => props.rollCnt > 1 ? '25px' : '0px'});
            height: calc(100% - 4px);
            background-image: linear-gradient(
              0deg,
              #81f305 0%,
              #009900 50%,
              #009121 75%,
              #81f305 100%
            );
            border-radius: 50px;
            margin: 0 2px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .streak-bonus-opacity {
            transition: width 0.5s ease;
            height: calc(100% - 4px);
            background: linear-gradient(
              90deg,
              rgba(204, 255, 0, 0) 0%,
              rgba(204, 255, 0, 0) 50%,
              rgba(204, 255, 0, 0.5) 75%,
              rgba(204, 255, 0, 1) 100%
            );
            border-radius: 50px;
            margin: 0 2px;
          }
        }
      }
      .pane-container {
        .pane-mask {
          bottom: -8px;
          padding: 6px 44px;
          span {
            font-size: 14px !important;
          }
        }
      }
      .bet-pane-container {
        width: ${sizes.mainWidthM}px;
        height: ${sizes.mainWidthM}px;
        .bet-pane-title {
          font-size: 20px;
        }
        .no-strikes {
          margin-bottom: 25px;
        }
      }
      .holes-container {
        user-select: none;
        margin-top: 12px;
        .holes-mask {
          top: -36px;
          span {
            font-size: 12px !important;
          }
        }
      }
      .btn-container {
        min-height: 58px;
        .roll-btn {
          animation: sign-animation 0s 0.1s steps(57) forwards;
        }
      }
      .instruction-container {
        padding: 0 10px;
        .toggle-wrapper-md {
          display: none;
        }
        .toggle-wrapper-sm {
          display: block;
        }
      }
    }
  }
`;

export const HolesContainer = styled.div`
  user-select: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  position: relative;
  flex-direction: column;
  .strike-popup {
    position: absolute;
    top: -55px;
    z-index: 1;
  }
  .holes {
    width: 300px;
    height: 55px;
    padding-left: 15px;
    background: url(${BgHolesContainer});
    background-size: 100% 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    position: relative;
    animation: holes ${(props) => (props.animate ? 1 : 0)}s infinite;
    animation-timing-function: ease-in-out;
    @keyframes holes {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }
    .holes-text {
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
      font-family: Roboto;
      font-size: 25.5px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
    .ant-space {
      position: absolute;
      right: 15px;
      display: flex;
      .hole {
        width: 33px;
        height: 33px;
        background: url(${BgHole});
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          font-size: 32px;
          font-weight: bold;
          color: #fd3435;
        }
      }
    }
  }
  .holes-mask {
    width: 100%;
    position: absolute;
    top: -40px;
    z-index: 1;
    background: rgba(0, 0, 0, 0.9);
    border-radius: 10px;
    border: solid #a029ca 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    .mask-strike-box-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 15px;
      .mask-strike-box {
        width: 20px;
        height: 15px;
        border: solid 2px white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;
const offset = 25;

export const MyDicesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  #dice1,
  #dice2 {
    width: 70px;
    height: 70px;
    transform-style: preserve-3d;
    transition: transform 5s ease-out;

    &.paused {
      animation-play-state: paused;
    }

    &.side-1-end {
      animation-duration: .25s !important;
      animation-iteration-count: 1 !important;
      animation-fill-mode: forwards;
      animation-name: rollDiceQuick, side1End !important;
    }

    &.side-2-end {
      animation-duration: .25s !important;
      animation-iteration-count: 1 !important;
      animation-fill-mode: forwards;
      animation-name: rollDiceQuick, side2End !important;
    }

    &.side-3-end {
      animation-duration: .25s !important;
      animation-iteration-count: 1 !important;
      animation-fill-mode: forwards;
      animation-name: rollDiceQuick, side3End !important;
    }

    &.side-4-end {
      animation-duration: .25s !important;
      animation-iteration-count: 1 !important;
      animation-fill-mode: forwards;
      animation-name: rollDiceQuick, side4End !important;
    }

    &.side-5-end {
      animation-duration: .25s !important;
      animation-iteration-count: 1 !important;
      animation-fill-mode: forwards;
      animation-name: rollDiceQuick, side5End !important;
    }

    &.side-6-end {
      animation-duration: .25s !important;
      animation-iteration-count: 1 !important;
      animation-fill-mode: forwards;
      animation-name: rollDiceQuick, side6End !important;
    }

    &.indefinite {
      animation-duration: 2s;
      animation-name: rollDice2;
      animation-iteration-count: infinite;
      animation-direction: normal;
      animation-timing-function: linear;
    }

    cursor: pointer;
    .sides {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      outline: none;
      box-shadow: inset -0.35rem 0.35rem 0.75rem rgba(0, 0, 0, 0.3),
        inset 0.5rem -0.25rem 0.5rem rgba(0, 0, 0, 0.15);
      border-radius: 20px;
      &.side-1 {
        transform: translateZ(${offset}px);
        background: url(${NewDice1});
        background-size: 100% 100%;
      }
      &.side-2 {
        transform: rotateX(-180deg) translateZ(${offset}px);
        background: url(${NewDice2});
        background-size: 100% 100%;
      }
      &.side-3 {
        transform: rotateY(90deg) translateZ(${offset}px);
        background: url(${NewDice3});
        background-size: 100% 100%;
      }
      &.side-4 {
        transform: rotateY(-90deg) translateZ(${offset}px);
        background: url(${NewDice4});
        background-size: 100% 100%;
      }
      &.side-5 {
        transform: rotateX(90deg) translateZ(${offset}px);
        background: url(${NewDice5});
        background-size: 100% 100%;
      }
      &.side-6 {
        transform: rotateX(-90deg) translateZ(${offset}px);
        background: url(${NewDice6});
        background-size: 100% 100%;
      }
    }
  }

  #dice2 {
    &.indefinite {
      animation-duration: 2s;
      animation-name: rollDice;
      animation-iteration-count: infinite;
      animation-direction: normal;
      animation-timing-function: linear;
    }

    &.side-1-end {
      animation-duration: .25s !important;
      animation-iteration-count: 1 !important;
      animation-fill-mode: forwards;
      animation-name: rollDiceQuick, side1End !important;
    }

    &.side-2-end {
      animation-duration: .25s !important;
      animation-iteration-count: 1 !important;
      animation-fill-mode: forwards;
      animation-name: rollDiceQuick, side2End !important;
    }

    &.side-3-end {
      animation-duration: .25s !important;
      animation-iteration-count: 1 !important;
      animation-fill-mode: forwards;
      animation-name: rollDiceQuick, side3End !important;
    }

    &.side-4-end {
      animation-duration: .25s !important;
      animation-iteration-count: 1 !important;
      animation-fill-mode: forwards;
      animation-name: rollDiceQuick, side4End !important;
    }

    &.side-5-end {
      animation-duration: .25s !important;
      animation-iteration-count: 1 !important;
      animation-fill-mode: forwards;
      animation-name: rollDiceQuick, side5End !important;
    }

    &.side-6-end {
      animation-duration: .25s !important;
      animation-iteration-count: 1 !important;
      animation-fill-mode: forwards;
      animation-name: rollDiceQuick, side6End !important;
    }
  }

  @keyframes rollDice {
    0% {
      transform: rotateX(450deg) rotateY(360deg) rotateZ(720deg);
    }
    100% {
      transform: rotateX(90deg) rotateY(0deg) rotateZ(0deg);
    }
  }

  @keyframes rollDice2 {
    0% {
      transform: rotateX(360deg) rotateY(450deg) rotateZ(720deg);
    }
    100% {
      transform: rotateX(0deg) rotateY(90deg) rotateZ(0deg);
    }
  }
    @keyframes rollDiceQuick {
    0% {
      transform: rotateX(60deg) rotateY(45deg) rotateZ(20deg);
    }
    100% {
      transform: rotateX(0deg) rotateY(90deg) rotateZ(0deg);
    }
  }


  @keyframes side1End {
    100% {
      transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
  }

  @keyframes side2End {
    100% {
      transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg);
    }
  }

  @keyframes side3End {
    100% {
      transform: rotateX(0deg) rotateY(270deg) rotateZ(0deg);
    }
  }

  @keyframes side4End {
    100% {
      transform: rotateX(0deg) rotateY(90deg) rotateZ(0deg);
    }
  }

  @keyframes side5End {
    100% {
      transform: rotateX(270deg) rotateY(0deg) rotateZ(0deg);
    }
  }

  @keyframes side6End {
    100% {
      transform: rotateX(90deg) rotateY(0deg) rotateZ(0deg);
    }
  }
`;

export const BottomContents = styled.div`
  position: relative;
  height: 60px;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: fadeUp 2s forwards;
  animation-delay: 6s;

  @keyframes fadeUp {
    0% {
      margin-top: -300px;
      opacity: 0;
    }

    100% {
      opacity: 1;
      margin-top: -250px;
      z-index: 2;
    }
  }
`;

export const RollButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  .btn-container {
    height: 102px;
  }
`;

export const DiceContainer = styled.div`
  position: relative;
`;

export const SideBetContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: start;
  &::nth-child(2) {
    justify-content: end;
  }
`;

export const SideBetButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 85px;
  width: 170px;
`;

export const SideBetButton = styled.button`
  display: flex;
  background-image: url(${props => props.accept ? SideBetAccept : SideBetDecline});
  background-size: 107px 111px;
  background-position: -13px -5px;
  background-color: transparent;
  width: 80px;
  height: 80px;
  font-size: 10pt;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto';
  color: #000;
  font-family: "Barlow Semi Condensed Bold";
  font-weight: 700;
  cursor: pointer;
  border: 0;
  padding: 0 10px;

  &:hover {
    transform: scale(1.05) translateZ(0);
  }
`;

export const BottomToggles = styled.div`
  display: flex;
  align-items: center;

  .auto-pick-toggle {
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
`;

export const SpeechBubble = styled.div`
  display: none;
`

export const MetalBeam = styled.div`
  background-image: url(${MetalBeamBackground});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 330px;
  height: 22px;
  position: absolute;
  z-index: 2;
  top: -8px;
  margin: auto;
  margin-left: 50%;
  transform: translateX(-50%);
`;